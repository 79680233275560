export const IS_LOADED = 'IS_LOADED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const ACTIVATE_FAIL = 'ACTIVATE_FAIL';
export const RESEND_ACTIVATE = 'RESEND_ACTIVATE';
export const ADD_PROFILE = 'ADD_PROFILE';
export const FORGET_PASS = 'FORGET_PASS';
export const RESET_PASSGET = 'RESET_PASSGET';
export const RESET_PASS = 'RESET_PASS';
export const UPDATE_PASS = 'UPDATE_PASS';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_ME = 'UPDATE_ME';
export const CONFIRM_NEWEMAIL = 'CONFIRM_NEWEMAIL';
export const DELETE_ME = 'DELETE_ME';
export const GET_ALLUSERS = 'GET_ALLUSERS';
export const GET_USER = 'GET_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_ALLPOSTS = 'GET_ALLPOSTS';
export const GET_POST = 'GET_POST';
export const CREATE_POST = 'CREATE_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const LIKE_POST = 'LIKE_POST';
export const GET_SLIDEPOSTS = 'GET_SLIDEPOSTS';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const GET_COMMENTS = 'GET_COMMENTS';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_MSG = 'CLEAR_MSG';

