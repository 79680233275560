import React from 'react';

export default function AboutSite() {

    return (
        <div className='about rounded p-3 px-4'>
            <h3 className='fw-semibold text-center'>About Us</h3>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sagittis, nulla gravida sollicitudin orci sed vestibulum. Justo, tristique donec urna pellentesque eget sollicitudin aliquam et, tortor. Neque, elementum tortor arcu, pulvinar vulputate luctus diam. Orci turpis vitae orci condimentum sit volutpat enim. Accumsan curabitur sagittis amet, massa ac facilisi adipiscing bibendum fermentum. Nec eget nulla risus a ut facilisi euismod semper eu
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non sagittis, nulla gravida sollicitudin orci sed vestibulum. Justo, tristique donec urna pellentesque eget sollicitudin aliquam et, tortor. Neque, elementum tortor arcu, pulvinar vulputate luctus diam. Orci turpis vitae orci condimentum sit volutpat enim. Accumsan curabitur sagittis amet, massa ac facilisi adipiscing bibendum fermentum. Nec eget nulla risus a ut facilisi euismod semper eu
            </p>
        </div>
    )
}
