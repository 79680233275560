import React from 'react';
import TopBar from '../components/TopBar';

export default function NotFound() {
    return (
        <div>
            <TopBar/>
            <h1>404</h1>
        </div>
    )
}
